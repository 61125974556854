<template>
  <!-- 钉钉鉴权+绑定 -->
  <div>
    <entSMS ref="entSMS" @beforeClose="beforeClose" />
  </div>
</template>

<script>
import {
  getDdSigurate,
  getDdUserid,
  checkDdUserid,
  bindEmpDdid
} from '@api/dingding.js'
import { checkUserLogin } from '@api/login.js'
import { BindingYzj } from '@api/wx.js'
import entSMS from '@components/entSMS'
import { Dialog } from 'vant'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  components: {
    entSMS
  },
  data() {
    return {
      userInfo,
      dduserid: localStorage.getItem('dduserid') || null
    }
  },
  created() {
    // 如果已经获取云之家id和empid 则 绑定
    if (localStorage.isYZJ && localStorage.YzjId && this.userInfo.empid) {
      BindingYzj({
        yzjuserid: localStorage.YzjId,
        empid: this.userInfo.empid
      }).then()
    }
    if (window.DingDing) {
      this.DingDingSign()
    }
  },
  methods: {
    // 钉钉，开始鉴权
    DingDingSign() {
      getDdSigurate({ url: encodeURIComponent(window.globalurls) }).then(
        res => {
          const j = {
            appId: res.agentId, // 必填，微应用ID
            agentId: res.agentId, // 必填，微应用ID
            corpId: res.corpId, //必填，企业ID
            timeStamp: res.sTimeStamp, // 必填，生成签名的时间戳
            nonceStr: res.noncestr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名
            type: 0, //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
            jsApiList: ['device.base.getUUID']
          }

          dd.config(j)
          dd.ready(() => {
            dd.runtime.permission.requestAuthCode({
              corpId: res.corpId,
              onSuccess: info => {
                if (window.dev) {
                  alert(JSON.stringify(info))
                }
                this.b4getDdUserid(info.code)
              }
            })
          })
          dd.error(error => {
            if (window.dev) {
              alert(
                JSON.stringify(error) + JSON.stringify(res) + window.globalurls
              )
            }
            //this.goto()
            this.$emit('beforeClose')
          })
        }
      )
    },
    // 获取钉钉id
    b4getDdUserid(code) {
      getDdUserid({ code: code }).then(res => {
        this.dduserid = res.userid
        localStorage.setItem('dduserid', this.dduserid)
        // 单点登陆
        const userInfo = localStorage.userInfo
        if (window.dev) {
          alert(window.globalurls)
          alert(JSON.stringify(res))
          alert(userInfo)
        }
        if (
          // !userInfo && //--不判断是否已经登陆，每次都从接口获取
          res.hasemp === '1' &&
          this.$route.path == '/login' &&
          sessionStorage.out != '1'
        ) {
          checkUserLogin({
            username: res.username,
            pwd: res.pwd,
            langid: 0,
            openid: ''
          }).then(res2 => {
            sessionStorage.setItem('out', '2')
            if (window.dev) {
              alert(JSON.stringify(res2))
            }
            if (res2.iserror == '0') {
              if (res2.data[0].userid != -1) {
                localStorage.setItem('v8_login_pwd', res.pwd)
                localStorage.setItem('userid', res2.data[0].userid)
                localStorage.setItem('userInfo', JSON.stringify(res2.data[0]))
                let fullpath = localStorage.getItem('V8_REDIRECT_PATH')
                if (window.dev) {
                  alert(
                    'localStorage.getItem("V8_REDIRECT_PATH")' +
                      localStorage.getItem('V8_REDIRECT_PATH')
                  )
                }
                if (fullpath) {
                  localStorage.setItem('V8_REDIRECT_PATH', '')
                  this.$router.push(fullpath)
                } else {
                  this.$router.push('/home?v=' + new Date().getTime())
                }
              }
            }
          })
        }
      })
    },
    checkDdUserid() {
      const userInfo = localStorage.userInfo
        ? JSON.parse(localStorage.userInfo)
        : {}
      checkDdUserid({
        dingdinguserid: this.dduserid,
        empid: userInfo.empid
      }).then(res2 => {
        if (window.dev) {
          alert(JSON.stringify(res2))
        }
        if (res2.error == '0') {
          // this.goto()
          this.$emit('beforeClose')
        } else if (res2.error == '2') {
          this.b4bindEmpDdid()
        } else {
          const msg =
            res2.error == '2'
              ? '该用户未绑定钉钉号!是否绑定此钉钉号?'
              : res2.error == '3'
              ? '该用户绑定的钉钉号与登陆钉钉号不一致!是否绑定此钉钉号?'
              : res2.error == '4'
              ? '该钉钉已被绑定，是否重新绑定?'
              : ''
          Dialog.confirm({
            message: msg,
            theme: 'round-button',
            confirmButtonText: '绑定',
            cancelButtonText: '重新登陆'
          })
            .then(() => {
              // on confirm
              this.$refs.entSMS.show = true
            })
            .catch(() => {
              // on cancel
              this.goLogin()
            })
        }
      })
    },
    // 绑定接口
    b4bindEmpDdid() {
      const userInfo = localStorage.userInfo
        ? JSON.parse(localStorage.userInfo)
        : {}
      bindEmpDdid({
        dingdinguserid: this.dduserid,
        empid: userInfo.empid
      }).then(res3 => {
        // alert(JSON.stringify(res3))
        if (window.dev) {
          alert(JSON.stringify(res3))
        }
        if (res3.error == '0') {
          //this.goto()
          this.$emit('beforeClose')
        }
      })
    },
    // 验证码框关闭
    beforeClose(action) {
      if (action === 'confirm') {
        // 验证码正确，进行绑定
        this.b4bindEmpDdid()
        this.$refs.entSMS.show = false
        this.$emit('beforeClose', action)
      } else {
        // 取消获取验证码
        // 重新登录
        this.goLogin()
      }
    },
    // 切换账号 尽可能不要使用路由的方式 可能导致数据不刷新 出错
    goLogin() {
      localStorage.setItem('v8_login_login', '0')
      localStorage.removeItem('userid')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('OpenId')
      sessionStorage.setItem('out', '1')
      this.$nextTick(() => {
        // this.$router.push('/login')
        window.location.href = '/login'
      })
    }
  }
}
</script>

<style scoped lang="less"></style>
